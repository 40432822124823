import CommonCss from "nlib/common/common.module.scss";

import Css from "./style.module.scss";

import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import React, { useMemo } from "react";
import SelectTasksFilter from "nlib/common/SelectTasksFilter";
import TaskFilters from "const/TaskFilters";
import classNames from "classnames";

const {
  ACTIVE_FOR_BUSINESS,
  ACTIVE_FOR_ACCOUNTANT,
  ALL_FOR_BUSINESS,
  ALL_FOR_ACCOUNTANT,
  ALL_ACTIVE_TASKS,
  ALL_TASKS
} = TaskFilters;

const Filters = (props) => {
  const {
    active,
    allTasksData,
    allActiveTasksData,
    tasksForBusinessData,
    activeTasksForBusinessData,
    tasksForAccountantData,
    activeTasksForAccountantData
  } = props;

  const { uiTexts } = useSelector(getTextsData);

  const selectorOptions = useMemo(() => {
    const closedTasksForBusinessCount = tasksForBusinessData.length - activeTasksForBusinessData.length;

    const closedTasksForAccountantCount = tasksForAccountantData.length - activeTasksForAccountantData.length;

    const closedTasksCount = allTasksData.length - allActiveTasksData.length;

    return [
      {
        label: `${uiTexts.tasksForClient} (${closedTasksForBusinessCount}/${tasksForBusinessData.length})`,
        completed: closedTasksForBusinessCount,
        all: tasksForBusinessData.length,
        value: ALL_FOR_BUSINESS
      },
      {
        label: `${uiTexts.activeTasksForClient} (${activeTasksForBusinessData.length})`,
        all: activeTasksForBusinessData.length,
        value: ACTIVE_FOR_BUSINESS
      },
      { divider: true },
      {
        label: `${uiTexts.tasksForAccountant} (${closedTasksForAccountantCount}/${tasksForAccountantData.length})`,
        completed: closedTasksForAccountantCount,
        all: tasksForAccountantData.length,
        value: ALL_FOR_ACCOUNTANT
      },
      {
        label: `${uiTexts.activeTasksForAccountant} (${activeTasksForAccountantData.length})`,
        all: activeTasksForAccountantData.length,
        value: ACTIVE_FOR_ACCOUNTANT
      },
      { divider: true },
      {
        label: `${uiTexts.allTasks} (${closedTasksCount}/${allTasksData.length})`,
        completed: closedTasksCount,
        all: allTasksData.length,
        value: ALL_TASKS
      },
      {
        label: `${uiTexts.allActiveTasks} (${allActiveTasksData.length})`,
        all: allActiveTasksData.length,
        value: ALL_ACTIVE_TASKS
      }
    ];
  }, [
    activeTasksForAccountantData.length,
    activeTasksForBusinessData.length,
    allActiveTasksData.length,
    allTasksData.length,
    tasksForAccountantData.length,
    tasksForBusinessData.length,
    uiTexts
  ]);

  return (
    <>
      <div className={classNames(Css.filters, active && Css.active)}>
        <div className={classNames(Css.container, CommonCss.mobileContainer)}>
          <div className={Css.title}>
            <span>{uiTexts.tasks}</span>
          </div>
          <SelectTasksFilter
            modal
            options={selectorOptions} />
        </div>
      </div>
    </>
  );
};

export default React.memo(Filters);
