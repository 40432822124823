import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getTextsData } from "selectors/texts";
import { getUserData } from "selectors/user";
import { useSelector } from "react-redux";
import React, { useCallback, useMemo } from "react";
import TaskStatuses from "const/TaskStatuses";
import UserRoles from "const/UserRoles";
import classNames from "classnames";
import useTaskContent from "hooks/useTaskContent";

const {
  HIGH_PRIORITY,
  COMPLETED,
  EXPIRED
} = TaskStatuses;

const MAX_TASK_PREVIEW_LENGTH = 256;

const STATUSES_CLASS_NAMES = {
  [COMPLETED]: Css.completed,
  [EXPIRED]: Css.expired,
  [HIGH_PRIORITY]: Css.highPriority
};

const TaskListItem = ({ task, onSelect }) => {
  const {
    id: taskId,
    createdBy,
    comments,
    targetUser,
    attachmentsCount,
    preview
  } = task;

  const {
    dueDateContent,
    status,
    creationDate,
    createdByContent,
    taskCreatedByYourSide
  } = useTaskContent(task);

  const { id: userId, role: userRole } = useSelector(getUserData);

  const { uiTexts } = useSelector(getTextsData);

  const businessUser = UserRoles.checkIsBusiness(userRole);

  const taskForContent = useMemo(() => {
    if (targetUser) {
      if (targetUser.id === userId) return uiTexts.forYou;

      const { fullName: taskForName } = targetUser;

      return taskForName;
    }

    if ((taskCreatedByYourSide && businessUser) || (!taskCreatedByYourSide && !businessUser)) {
      return uiTexts.forAccountant;
    }

    return uiTexts.forClient;
  }, [targetUser, taskCreatedByYourSide, businessUser, uiTexts, userId]);

  const handleItemClick = useCallback(() => {
    onSelect(taskId);
  }, [taskId, onSelect]);

  return (
    <div
      className={classNames(
        Css.taskListItem,
        targetUser && targetUser.id === userId && Css.forYou,
        STATUSES_CLASS_NAMES[status]
      )}
      onClick={handleItemClick}>
      <div className={Css.head}>
        {!!dueDateContent && (
          <div className={Css.dueDate}>
            {dueDateContent || uiTexts.noDate}
          </div>
        )}
        <div className={Css.rightSide}>
          {taskForContent && (
            <div className={Css.user}>
              <Icons.User weight="bold" />
              <span>{taskForContent}</span>
            </div>
          )}
        </div>
      </div>
      <div className={Css.content}>
        {preview + (preview.length >= MAX_TASK_PREVIEW_LENGTH ? "..." : "")}
      </div>
      <div className={Css.footer}>
        <div className={Css.createdBy}>
          {!!createdBy && (
            <span>{createdByContent}</span>
          )}
          <span>{creationDate}</span>
        </div>
        <div className={Css.counters}>
          {!!attachmentsCount && (
            <div className={Css.counter}>
              <Icons.FileText />
              <span>{attachmentsCount}</span>
            </div>
          )}
          {!!comments.all && (
            <div className={classNames(Css.counter, comments.unread && Css.primary)}>
              <Icons.ChatText />
              <span>{comments.all}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(TaskListItem);
