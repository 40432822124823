import { useDispatch } from "react-redux";
import Form from "nlib/common/Form";
import FormContent from "./lib/FormContent";
import React, { useCallback, useState } from "react";
import TaskFilters from "const/TaskFilters";
import TasksActions from "actions/TasksActions";
import UiActions from "actions/UiActions";
import useEnvVars from "hooks/useEnvVars";

const { ALL_ACTIVE_TASKS } = TaskFilters;

const INITIAL_VALUES = { text: "", dueDate: "", targetUser: "" };

const validation = ({ text }) => {
  return text.trim() ? null : { text: "required" };
};

const TaskForm = ({ className }) => {
  const [, setEnvVars] = useEnvVars();

  const dispatch = useDispatch();

  const [attachments, setAttachments] = useState([]);

  const [fetching, setFetching] = useState(false);

  const handleFormSubmit = useCallback(async({ values }) => {
    const { text, targetUser, dueDate } = values;

    setFetching(true);

    dispatch(UiActions.setTasksFilter(ALL_ACTIVE_TASKS));

    const files = attachments.map(({ file }) => file);

    const result = await dispatch(TasksActions.addNewTask(false, text, targetUser, dueDate, files));

    if (result) {
      dispatch(TasksActions.fetchTasksList(false, false));
      setEnvVars({ editTask: null });
    } else {
      setFetching(false);
    }
  }, [dispatch, attachments, setEnvVars]);

  const handleCancel = useCallback(() => {
    setEnvVars({ editTask: null });
  }, [setEnvVars]);

  return (
    <Form
      component={null}
      initialValues={INITIAL_VALUES}
      validation={validation}
      onSubmit={handleFormSubmit}>
      {({ values, hasChanges, onSubmit }) => {
        return (
          <FormContent
            className={className}
            disabled={fetching}
            values={values}
            attachments={attachments}
            setAttachments={setAttachments}
            hasChanges={hasChanges}
            onSubmit={onSubmit}
            onCancel={handleCancel} />
        );
      }}
    </Form>
  );
};

export default React.memo(TaskForm);
