import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { checkTasksFetching, getActiveTasksData, getTasksData } from "selectors/tasks";
import { getAllUsersData } from "selectors/organizations";
import { getTextsData } from "selectors/texts";
import { getUserData } from "selectors/user";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import Activity from "nlib/common/Activity";
import Attachments from "nlib/common/Attachments";
import Button from "nlib/ui/Button";
import Checkbox from "nlib/ui/Checkbox";
import CommentsActions from "actions/CommentsActions";
import CustomDropArea from "nlib/common/CustomDropArea";
import DataConstants from "const/DataConstants";
import Preloader from "nlib/ui/Preloader";
import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";
import SideBar, { SideBarContent, SideBarHeader } from "../SideBar";
import TasksActions from "actions/TasksActions";
import UserRoles from "const/UserRoles";
import Utils from "utils/Utils";
import classNames from "classnames";
import moment from "moment";
import useEnvVars from "hooks/useEnvVars";
import useTaskContent from "hooks/useTaskContent";

const { COMMENT_TARGET_TYPES: { TASKS }, COMMENT_TYPES: { COMMENT } } = DataConstants;

const TaskContent = ({ className, taskId, mobile }) => {
  const [, setEnvVars] = useEnvVars();

  const dispatch = useDispatch();

  const { uiTexts, messages } = useSelector(getTextsData);

  const allUsersData = useSelector(getAllUsersData);

  const userData = useSelector(getUserData);

  const tasksFetching = useSelector(checkTasksFetching);

  const tasksData = useSelector(getTasksData);

  const activeTasksData = useSelector(getActiveTasksData);

  const [content, setContent] = useState({});

  const [showAll, setShowAll] = useState(false);

  const [activityLoaded, setActivityLoaded] = useState(!taskId);

  const { completed } = Utils.arrayFindById(tasksData, taskId) || Utils.arrayFindById(activeTasksData, taskId) || {};

  const title = useMemo(() => {
    if (!content?.createdBy) return uiTexts.task;

    const targetUserData = Utils.arrayFindById(allUsersData, content.targetUser);

    const forClient = targetUserData
      ? UserRoles.checkIsBusiness(targetUserData.role)
      : UserRoles.checkIsAccountant(content.createdBy.role);

    return forClient
      ? uiTexts.taskForClient
      : uiTexts.taskForAccountant;
  }, [allUsersData, content, uiTexts]);

  const {
    dueDateContent,
    createdByContent,
    taskCreatedByYourSide
  } = useTaskContent(content);

  const businessUser = UserRoles.checkIsBusiness(userData.role);

  const taskForContent = useMemo(() => {
    if (!content.createdBy) return null;

    const { targetUser } = content;

    if (targetUser) {
      if (targetUser === userData.id) return uiTexts.forYou;

      const { fullName: taskForName } = Utils.arrayFindById(allUsersData, targetUser, {});

      return taskForName;
    }

    if ((taskCreatedByYourSide && businessUser) || (!taskCreatedByYourSide && !businessUser)) {
      return uiTexts.forAccountant;
    }

    return uiTexts.forClient;
  }, [businessUser, content, taskCreatedByYourSide, uiTexts, userData.id, allUsersData]);

  const onClose = useCallback(() => {
    setEnvVars({ editTask: null });
  }, [setEnvVars]);

  const editTask = useCallback(async(taskData) => {
    if (!taskData) return null;

    const { id, text, dueDate, attachments, files } = taskData;

    const response = await dispatch(
      TasksActions.editTask(
        id,
        taskData.completed,
        text,
        dueDate || "",
        attachments,
        files
      )
    );

    if (!response) return null;

    if (Array.isArray(files) && files.length) {
      await dispatch(
        CommentsActions.addNewComment(
          COMMENT,
          `${messages.filesAttached}: ${files.map(({ name }) => name).join(", ")}`,
          [],
          false,
          response.id,
          TASKS
        )
      );
    }

    dispatch(TasksActions.fetchTasksList(false, true));

    return response;
  }, [messages.filesAttached, dispatch]);

  const updateContent = useCallback((data) => {
    setContent((prev) => ({ ...prev, ...data }));
  }, []);

  const fetchData = useCallback(async() => {
    setContent({});
    setActivityLoaded(false);

    const [result] = await Promise.all([
      dispatch(TasksActions.fetchTask(taskId)),
      dispatch(CommentsActions.fetchComments(TASKS, taskId, true))
    ]);

    if (!result) onClose();

    updateContent(result);
    setActivityLoaded(true);
  }, [dispatch, taskId, updateContent, onClose]);

  const handleBackClick = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleCompleteClick = useCallback(async() => {
    const data = { ...content, completed: !completed };

    setContent(data);

    const result = await editTask(data);

    if (result) {
      dispatch(
        CommentsActions.addNewComment(
          COMMENT,
          completed ? messages.unmarkedAsCompleted : messages.markedAsCompleted,
          [],
          false,
          taskId,
          TASKS
        )
      );
    }
  }, [completed, content, dispatch, editTask, messages, taskId]);

  const handleFilesChange = useCallback(async(files) => {
    setContent((prev) => ({
      ...prev,
      attachments: [
        ...prev.attachments,
        ...files.map(({ name }) => ({
          loading: true,
          originalName: name,
          id: uuid()
        }))
      ]
    }));

    setShowAll(true);

    const taskData = await editTask({ ...content, files });

    setContent(taskData || content);
  }, [content, editTask]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, [taskId, fetchData, dispatch]);

  return (
    <SideBar className={classNames(Css.taskContent, className)}>
      <SideBarHeader onCloseClick={handleBackClick}>{title}</SideBarHeader>
      <SideBarContent>
        {(!content || !activityLoaded)
          ? <Preloader />
          : (
            <div className={Css.contentWrap}>
              <div className={Css.col}>
                <div className={Css.header}>
                  <div className={Css.info}>
                    <div className={Css.infoItem}>
                      <div className={Css.infoItemTitle}>{uiTexts.taskFor}</div>
                      <div className={Css.infoItemValue} title={taskForContent}>
                        <Icons.User />
                        <span>{taskForContent}</span>
                      </div>
                    </div>
                    <div className={Css.infoItem}>
                      <div className={Css.infoItemTitle}>{uiTexts.created}</div>
                      <div className={Css.infoItemValue}>
                        <span>{moment.utc(content.createdAt).fromNow()}</span>
                      </div>
                    </div>
                    {dueDateContent && (
                      <div className={Css.infoItem}>
                        <div className={Css.infoItemTitle}>{uiTexts.deadline}</div>
                        <div className={Css.infoItemValue}>
                          <span>{dueDateContent}</span>
                        </div>
                      </div>
                    )}
                    {createdByContent && (
                      <div className={Css.infoItem}>
                        <div className={Css.infoItemTitle}>{uiTexts.createdBy}</div>
                        <div className={Css.infoItemValue}>
                          <span>{createdByContent}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <Button
                    large block outline
                    disabled={tasksFetching}
                    className={Css.completeButton}
                    onClick={handleCompleteClick}>
                    <Checkbox toggle checked={completed} />
                    <span>{completed ? uiTexts.unmarkAsComplete : uiTexts.markAsComplete}</span>
                  </Button>
                </div>
                <div className={Css.title}>{uiTexts.fullDescription}</div>
                <div className={Css.text}>{content.text}</div>
                <div className={Css.title}>{uiTexts.attachedFiles}</div>
                <Attachments
                  className={Css.attachments}
                  showAll={showAll || undefined}
                  attachments={content.attachments} />
                <CustomDropArea
                  disabled={tasksFetching}
                  onChange={handleFilesChange} />
              </div>
              <div className={Css.col}>
                <div className={Css.title}>{uiTexts.activity}</div>
                <Activity
                  mobile={mobile}
                  dataLoadedInitial
                  disabled={tasksFetching}
                  className={Css.activity}
                  type={TASKS}
                  itemId={taskId} />
              </div>
            </div>
          )}
      </SideBarContent>
    </SideBar>
  );
};

export default React.memo(TaskContent);
