import CommonCss from "nlib/common/common.module.scss";

import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getTextsData } from "selectors/texts";
import { getUserData } from "selectors/user";
import { useSelector } from "react-redux";
import Badge from "nlib/ui/Badge";
import Button from "nlib/ui/Button";
import Constants from "const/Constants";
import Filters from "./lib/Filters";
import ListItemDetails from "./lib/ListItemDetails";
import NoDataContent from "nlib/common/NoDataContent";
import PageActionButtons from "mlib/common/PageActionButtons";
import Preloader from "nlib/common/Preloader";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Switcher from "nlib/ui/Switcher";
import TaskFilters from "const/TaskFilters";
import TaskListItem from "./lib/TaskListItem";
import UserRoles from "const/UserRoles";
import Utils from "utils/Utils";
import classNames from "classnames";
import useEnvVars from "hooks/useEnvVars";
import useTasks from "hooks/useTasks";

const {
  ACTIVE_FOR_BUSINESS,
  ACTIVE_FOR_ACCOUNTANT,
  ALL_FOR_BUSINESS,
  ALL_FOR_ACCOUNTANT,
  ALL_ACTIVE_TASKS,
  ALL_TASKS
} = TaskFilters;

const TasksPage = () => {
  const [{ editTask }, setEnvVars] = useEnvVars();

  const { uiTexts, messages } = useSelector(getTextsData);

  const [editTaskId] = editTask ? editTask.split(".") : [];

  const {
    fetchingData: tasksFetching,
    tasksFilter,
    allTasksData,
    filteredTasksData,
    allActiveTasksData,
    tasksForBusinessData,
    tasksForAccountantData,
    activeTasksForBusinessData,
    activeTasksForAccountantData
  } = useTasks();

  const userData = useSelector(getUserData);

  const [showForAll, setShowForAll] = useState(true);

  const editTaskData = Utils.arrayFindById(allTasksData, editTaskId);

  const tasksOnlyForCurrentUser = useMemo(() => {
    return filteredTasksData ? filteredTasksData.filter((task) => {
      const { targetUser } = task;

      const targetUserId = targetUser ? targetUser.id : null;

      return targetUserId === userData.id;
    }) : [];
  }, [filteredTasksData, userData.id]);

  const tasksToDisplay = showForAll ? filteredTasksData : tasksOnlyForCurrentUser;

  const showAdditionalSwitcher = [
    ALL_ACTIVE_TASKS,
    ALL_TASKS,
    ...UserRoles.checkIsAccountant(userData.role)
      ? [ACTIVE_FOR_ACCOUNTANT, ALL_FOR_ACCOUNTANT]
      : [ACTIVE_FOR_BUSINESS, ALL_FOR_BUSINESS]
  ].includes(tasksFilter);

  const switcherOptions = useMemo(() => {
    return [
      {
        value: true,
        label: (
          <>
            <span>{uiTexts.forAll}</span>
            <Badge counter className={Css.counter}>{filteredTasksData.length}</Badge>
          </>
        )
      },
      {
        value: false,
        label: (
          <>
            <span>{uiTexts.onlyForMe}</span>
            <Badge counter className={Css.counter}>{tasksOnlyForCurrentUser.length}</Badge>
          </>
        )
      }
    ];
  }, [filteredTasksData.length, tasksOnlyForCurrentUser.length, uiTexts]);

  const handleItemDetailClose = useCallback(() => {
    setEnvVars({ editTask: null });
  }, [setEnvVars]);

  const handleTaskSelect = useCallback((itemId) => {
    setEnvVars({ editTask: itemId });
  }, [setEnvVars]);

  const handleSwitcherChange = useCallback((value) => {
    setShowForAll(value);
  }, []);

  const handleAddTaskButtonClick = useCallback(() => {
    setEnvVars({ editTask: Constants.NEW_ENTITY_ID });
  }, [setEnvVars]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Filters
        tasksFilter={tasksFilter}
        allTasksData={allTasksData}
        allActiveTasksData={allActiveTasksData}
        tasksForBusinessData={tasksForBusinessData}
        activeTasksForBusinessData={activeTasksForBusinessData}
        tasksForAccountantData={tasksForAccountantData}
        activeTasksForAccountantData={activeTasksForAccountantData} />
      <div className={classNames(Css.tasksPage, CommonCss.mobileContainer)}>
        <div className={Css.content}>
          {showAdditionalSwitcher && (
            <Switcher
              className={Css.switcher}
              value={showForAll}
              options={switcherOptions}
              onChange={handleSwitcherChange} />
          )}
          {tasksToDisplay.length
            ? tasksToDisplay.map((task) => (
              <TaskListItem
                mobile
                key={task.id}
                task={task}
                onSelect={handleTaskSelect} />
            ))
            : (
              tasksFetching
                ? <Preloader />
                : (
                  <NoDataContent
                    title={tasksFilter !== ALL_ACTIVE_TASKS && uiTexts.noTasks}
                    description={tasksFilter === ALL_ACTIVE_TASKS && messages.tasksBlockPlaceholder} />
                ))}
          {editTaskId && (
            <ListItemDetails
              taskId={editTaskId}
              data={editTaskData}
              onClose={handleItemDetailClose} />
          )}
        </div>
        <PageActionButtons>
          <Button
            primary
            icon={Icons.Plus}
            onClick={handleAddTaskButtonClick}>
            {uiTexts.addNewTask}
          </Button>
        </PageActionButtons>
      </div>
    </>
  );
};

export default React.memo(TasksPage);
