import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getSelectedBusinessUsersData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { getUserData } from "selectors/user";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import Attachments from "nlib/common/Attachments";
import Button from "nlib/ui/Button";
import CustomDropArea from "nlib/common/CustomDropArea";
import DatePickerInput from "nlib/common/DatePickerInput";
import FormElement from "nlib/common/Form/lib/FormElement";
import FormLabel from "nlib/common/FormLabel";
import React, { useCallback, useMemo } from "react";
import Select from "nlib/ui/Select";
import SideBar, { SideBarContent, SideBarFooter, SideBarHeader } from "nlib/common/SideBar";
import UserRoles from "const/UserRoles";
import classNames from "classnames";
import useShowCommonModal from "hooks/useShowCommonModal";

const FormContent = (props) => {
  const {
    className,
    disabled,
    values,
    attachments,
    setAttachments,
    onSubmit,
    onCancel
  } = props;

  const { text, dueDate, targetUser } = values;

  const hasText = !!text.trim();

  const showCommonModal = useShowCommonModal();

  const { uiTexts, messages } = useSelector(getTextsData);

  const usersData = useSelector(getSelectedBusinessUsersData);

  const userData = useSelector(getUserData);

  const minDate = useMemo(() => {
    const date = new Date();

    date.setHours(0, 0, 0, 0);

    return date;
  }, []);

  const usersGroups = useMemo(() => {
    return [
      { value: 0, label: uiTexts.accountants },
      { value: 1, label: uiTexts.clients }
    ];
  }, [uiTexts]);

  const usersOptions = useMemo(() => {
    return [
      ...usersData
        .map(({ id, email, fullName, role }) => {
          return {
            group: UserRoles.checkIsAccountant(role) ? 0 : 1,
            value: id,
            label: email === fullName ? fullName : `${fullName} (${email})`
          };
        })
    ];
  }, [usersData]);

  const handleCloseClick = useCallback(async() => {
    if (disabled) onCancel();
    else {
      if (hasText || dueDate || attachments.length) {
        const result = await showCommonModal({
          confirm: true,
          text: messages.closeWhenHasUnsavedChanges
        });

        if (!result) return;
      }
      onCancel();
    }
  }, [disabled, onCancel, hasText, dueDate, attachments.length, showCommonModal, messages]);

  const handleFilesChange = useCallback((newFiles) => {
    const newItems = [...newFiles].map((file) => {
      return { id: uuid(), createdAt: new Date(), originalName: file.name, file };
    });

    setAttachments((prev) => [...prev, ...newItems]);
  }, [setAttachments]);

  const handleDeleteAttachment = useCallback((id) => {
    setAttachments((prev) => prev.filter((item) => item.id !== id));
  }, [setAttachments]);

  return (
    <SideBar className={classNames(Css.formContent, className)}>
      <SideBarHeader disabled={disabled} onCloseClick={handleCloseClick}>
        {UserRoles.checkIsBusiness(userData.role)
          ? uiTexts.taskForAccountant
          : uiTexts.taskForClient}
      </SideBarHeader>
      <SideBarContent>
        <div className={Css.contentWrap}>
          <div className={Css.col}>
            <div className={Css.formRow}>
              <FormLabel>{uiTexts.text}</FormLabel>
              <FormElement
                multiline
                disabled={disabled}
                name="text"
                invalid={!hasText}
                placeholder={uiTexts.enterTaskText}
                className={Css.formElement} />
            </div>
            <div className={Css.formRow}>
              <FormLabel>{uiTexts.taskFor}</FormLabel>
              <FormElement
                disabled={disabled}
                name="targetUser"
                placeholder={uiTexts.selectUser}
                iconBefore={Icons.User}
                className={Css.formElement}
                invalid={!targetUser}
                options={usersOptions}
                groups={usersGroups}
                element={Select} />
            </div>
            <div className={Css.formRow}>
              <FormLabel>{uiTexts.term}</FormLabel>
              <FormElement
                portal
                disabled={disabled}
                name="dueDate"
                toggleButton={null}
                iconBefore={Icons.Calendar}
                placeholder={uiTexts.enterDate}
                className={Css.formElement}
                minDate={minDate}
                element={DatePickerInput} />
            </div>
          </div>
          <div className={Css.col}>
            <FormLabel>{uiTexts.attachments}</FormLabel>
            {!!attachments.length && (
              <Attachments
                showAll
                disabled={disabled}
                useConfirm={false}
                attachments={attachments}
                onDelete={handleDeleteAttachment} />
            )}
            <div className={Css.formRow}>
              <CustomDropArea
                disabled={disabled}
                onChange={handleFilesChange} />
            </div>
          </div>
        </div>
      </SideBarContent>
      <SideBarFooter className={Css.footer}>
        <Button
          large block primary
          disabled={disabled || !hasText || !targetUser}
          onClick={onSubmit}>
          {disabled ? <Icons.Spinner data-wait /> : <Icons.Plus />}
          <span>{uiTexts.create}</span>
        </Button>
        <Button
          large block outline
          disabled={disabled}
          onClick={handleCloseClick}>
          <span>{uiTexts.cancel}</span>
        </Button>
      </SideBarFooter>
    </SideBar>
  );
};

export default React.memo(FormContent);
